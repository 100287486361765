import React, { useState } from "react";
import "../styles/login.scss";
import { useLogin } from "../hooks/useLogin";

function Login() {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const { loginUser, error, isLoading } = useLogin();

  const handleSubmit = async () => {
    await loginUser(login, password);
  };
  return (
    <div className="login-main">
      <div className="login-content">
        <p>Panel Logowania</p>
        <hr />
        <div>
          <input
            type="text"
            name="login"
            placeholder="Login"
            onChange={(e) => setLogin(e.target.value)}
          />
        </div>
        <div>
          <input
            type="password"
            name="password"
            placeholder="Hasło"
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button type="submit" onClick={handleSubmit} disabled={isLoading}>
          Zaloguj się
        </button>
        {error && <div className="error">{error}</div>}
      </div>
    </div>
  );
}

export default Login;
