import React, { useState, useEffect } from "react";
import { useAuthContext } from "../hooks/useAuthContext";
import Axios from "axios";
import "../styles/deliverytimes.scss";

const DeliveryTime = () => {
  const { user } = useAuthContext();
  const [deliveryTimes, setDeliveryTimes] = useState([]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    const updatedDeliveryTimes = [...deliveryTimes];

    const index = updatedDeliveryTimes.findIndex(
      (item) => item.voivodeship === name
    );

    if (index !== -1) {
      updatedDeliveryTimes[index] = {
        ...updatedDeliveryTimes[index],
        days: parseInt(value),
      };

      setDeliveryTimes(updatedDeliveryTimes);

      Axios.patch(
        `/api/kennels/deliverytimes`,
        { days: value, voivodeship: name },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      )
        .then()
        .catch((error) => {
          console.error("Error updating order:", error);
        });
    }
  };

  const handleSubmit = async () => {
    console.log(deliveryTimes);
  };

  useEffect(() => {
    if (user) {
      Axios.get(`/api/kennels/deliverytimes`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
        .then(({ data }) => {
          setDeliveryTimes(data);
          console.log(data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [user]);
  return (
    <div className="deliverytimes-main">
      <div className="grid-container">
        {deliveryTimes.map((item, index) => (
          <div key={index} className="grid-item">
            <div>{item.voivodeship}</div>
            <input
              type="number"
              name={item.voivodeship}
              value={item.days}
              onChange={handleInputChange}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default DeliveryTime;
