import "./App.scss";
import { Navigate } from "react-router-dom";
import { Route, Routes } from "react-router-dom"; // Import Router and Switch
import Home from "./pages/Home";
import Login from "./pages/Login";
import Navbar from "./components/Navbar";
import Order from "./pages/Order";
import New from "./pages/New";
import Edit from "./pages/Edit";
import Map from "./components/Map";
import { useState } from "react";
import FloorStandsGenerator from "./pages/FloorStandsGenerator";
import { useAuthContext } from "./hooks/useAuthContext";
import DeliveryTime from "./pages/DeliveryTime";

function App() {
  const [refresh, setRefresh] = useState(false);
  const { user } = useAuthContext();

  function AuthRoute({ element, user }) {
    return user ? element : <Navigate to="/login" replace={true} />;
  }

  const routes = [
    {
      path: "/",
      element: <AuthRoute element={<Home refresh={refresh} />} user={user} />,
    },
    {
      path: "/new",
      element: <AuthRoute element={<New />} user={user} />,
    },
    {
      path: "/map",
      element: <AuthRoute element={<Map />} user={user} />,
    },
    {
      path: "/deliverytime",
      element: <AuthRoute element={<DeliveryTime />} user={user} />,
    },
    {
      path: "/generator",
      element: <AuthRoute element={<FloorStandsGenerator />} user={user} />,
    },
    {
      path: "/order/:id",
      element: <AuthRoute element={<Order />} user={user} />,
    },
    {
      path: "/edit/:id",
      element: <AuthRoute element={<Edit />} user={user} />,
    },
    // Add other routes here as needed
    {
      path: "*",
      element: <Navigate to="/" replace={true} />,
    },
  ];

  const handleRefreshClick = () => {
    setRefresh(!refresh);
  };
  return (
    <div className="app">
      <Navbar onRefreshClick={handleRefreshClick} />
      <Routes>
        <Route
          path="/login"
          element={!user ? <Login /> : <Navigate to="/" />}
        />
        {routes.map((route) => (
          <Route key={route.path} path={route.path} element={route.element} />
        ))}
      </Routes>
    </div>
  );
}

export default App;
