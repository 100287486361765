import React from "react";
import "../styles/navbar.scss";
import { BiRefresh, BiLogOut, BiMap, BiTimer } from "react-icons/bi";
import { BsGrid3X3Gap } from "react-icons/bs";
import { PiDogLight, PiCarLight } from "react-icons/pi";
import { AiFillFileAdd } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useLogout } from "../hooks/useLogout";

function Navbar({ onRefreshClick }) {
  const navigate = useNavigate();
  const { logout } = useLogout();
  // const { user } = useAuthContext();

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  return (
    <div className="navigation">
      <div className="refresh-icon">
        <Link to={"/"}>
          <BiRefresh onClick={onRefreshClick} color="white" />
        </Link>
      </div>
      <hr />
      <div className="kennels-icon categories-icon">
        <PiDogLight color="white" />
      </div>
      <div className="garages-icon categories-icon">
        <PiCarLight color="white" />
      </div>
      <hr />
      <Link to={"/new"} className="new-icon categories-icon">
        <AiFillFileAdd color="white" />
      </Link>
      <Link to={"/generator"} className="grid-icon categories-icon">
        <BsGrid3X3Gap color="white" />
      </Link>
      <Link to={"/deliverytime"} className="deliverytime-icon categories-icon">
        <BiTimer color="white" />
      </Link>
      <Link to={"/map"} className="map-icon categories-icon">
        <BiMap color="white" />
      </Link>
      <div className="user-icon">
        <BiLogOut color="white" onClick={handleLogout}/>
      </div>
    </div>
  );
}

export default Navbar;
