import React from "react";
import { Link } from "react-router-dom";

const KennelOrderItem = ({ kennelOrder }) => {
  return (
    <div>
      <Link
        to={`/order/${kennelOrder.ordernumber}`}
        key={kennelOrder.ordernumber}
        className="order-row"
      >
        <div>{kennelOrder.ordernumber}</div>
        <div>{kennelOrder.date.slice(0, 10)}</div>
        <div>
          {kennelOrder.name} {kennelOrder.surname}
        </div>
        <div>
          {kennelOrder.widthcm / 100} × {kennelOrder.lengthcm / 100}
        </div>
        <div className="content-agent">{kennelOrder.agent}</div>
        <div
          className={
            "content-status " +
            (kennelOrder.status === "w trakcie realizacji"
              ? "status-gray"
              : "status-green")
          }
        >
          {kennelOrder.status}
        </div>
        <div>{kennelOrder.voivodeship.toUpperCase()}</div>
        <div>{kennelOrder.price} zł</div>
      </Link>
    </div>
  );
};

export default KennelOrderItem;
