import { useMap } from "react-leaflet";
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
import "leaflet-routing-machine";
import React from "react";
import L from "leaflet";

function RoutingControl({ waypoints }) {
  const map = useMap();

  React.useEffect(() => {
    if (waypoints.length > 1) {
      // Initialize routing control
    //   L.Routing.control({
    //     waypoints,
    //     routeWhileDragging: true, // Enable route updating while dragging waypoints
    //   }).addTo(map);
    }
    // map.on('click', (e) => {
    //     console.log(e)
    // })
  }, [map, waypoints]);

  return null;
}

export default RoutingControl;
