import React, { useState } from "react";

function generateXSchema(width, length) {
  const numRows = Math.ceil(length);
  const numCols = Math.ceil(width);
  const schema = [];

  for (let i = 0; i <= numRows; i++) {
    const row = [];
    for (let j = 0; j <= numCols; j++) {
      row.push("×");
    }
    schema.push(row.join(" "));
  }

  return schema;
}

function FloorStandsGenerator() {
  const [width, setWidth] = useState(3);
  const [length, setLength] = useState(3);
  const [schema, setSchema] = useState(generateXSchema(3, 3));

  const handleGenerateSchema = () => {
    setSchema(generateXSchema(width, length));
  };

  return (
    <div className="generator-main">
      <h1>Generator Schematu Rozstawu Bloczków</h1>
      <div className="form">
        <label htmlFor="width">Szerokość:</label>
        <input
          type="number"
          id="width"
          value={width}
          onChange={(e) => setWidth(e.target.value)}
        />
        <label htmlFor="length">Długość:</label>
        <input
          type="number"
          id="length"
          value={length}
          onChange={(e) => setLength(e.target.value)}
        />
        <button onClick={handleGenerateSchema}>Generuj</button>
      </div>
      <div className="schema">
        <hr />
        {schema.map((row, index) => (
          <div key={index}>{row}</div>
        ))}
      </div>
    </div>
  );
}

export default FloorStandsGenerator;
