import React, { useEffect, useState } from "react";
import Axios from "axios";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
import "leaflet-routing-machine";
import RoutingControl from "./RoutingControl";
import "../styles/map.scss";
import { useAuthContext } from "../hooks/useAuthContext";

function Map() {
  const [isSearchBarFocused, setIsSearchBarFocused] = useState(true);
  const martikstalPosition = [49.75578, 20.25517];
  const [newLat, setNewLat] = useState(null);
  const [newLng, setNewLng] = useState(null);
  const [kennelOrdersLocationsList, setKennelOrdersLocationsList] = useState(
    []
  );
  const [newPostalCode, setNewPostalCode] = useState(null);
  const [newCity, setNewCity] = useState(null);
  const { user } = useAuthContext();

  useEffect(() => {
    if (user) {
      Axios.get(`/api/kennels/`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
        .then((response) => {
          setKennelOrdersLocationsList(response.data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [user]);

  function markerColor(colorCode) {
    return new L.Icon({
      iconUrl: `https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-${colorCode}.png`,
      shadowUrl:
        "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41],
    });
  }

  const geocageApiKey = "6df0b0a3516e4018b5dafd3b161b6a05";

  async function geocodeAddress(postalCode) {
    try {
      const placename = `${postalCode}, POLAND`;
      const response = await Axios.get(
        `https://api.opencagedata.com/geocode/v1/json?q=${placename}&key=${geocageApiKey}&language=pl`
      );
      setNewLat(response.data.results[0].geometry.lat);
      setNewLng(response.data.results[0].geometry.lng);
      setNewPostalCode(postalCode);
      response.data.results[0].components.city !== undefined
        ? setNewCity(response.data.results[0].components.city)
        : response.data.results[0].components.town !== undefined
        ? setNewCity(response.data.results[0].components.town)
        : setNewCity(response.data.results[0].components.village);
    } catch (error) {
      console.error("Error:", error.message);
    }
  }

  const handleSearchBarFocus = () => {
    setIsSearchBarFocused(true);
  };

  const handleSearchBarBlur = () => {
    setIsSearchBarFocused(false);
  };

  const handleKeyPress = (e) => {
    e.key === "Enter" ? handleShowPosition() : handleSearchBarBlur();
  };

  const handleShowPosition = async () => {
    const e = document.querySelector(".map-searchbar");
    console.log("piesek");
    await geocodeAddress(e.value);
    setIsSearchBarFocused(true);
  };

  return (
    <MapContainer
      center={[52.0, 19.0]}
      zoom={6}
      style={{ height: "100%", width: "100%" }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />

      <Marker position={martikstalPosition} icon={markerColor("blue")}>
        <Popup>Martikstal</Popup>
      </Marker>

      {newLat !== null &&
        newLng !== null &&
        newLat !== 52.215933 &&
        newLng !== 19.134422 &&
        newPostalCode.length === 6 && (
          <Marker position={[newLat, newLng]} icon={markerColor("red")}>
            <Popup>
              {newPostalCode} {newCity}
            </Popup>
          </Marker>
        )}

      {kennelOrdersLocationsList.map((location, index) => (
        <Marker
          key={index}
          position={[location.lat, location.lng]}
          icon={markerColor("orange")}
        >
          <Popup>
            Numer {location.ordernumber}
            <br />
            {location.name} {location.surname}
            <br />
            {location.postalcode} {location.city}
            <br />
            {location.widthcm / 100}×{location.lengthcm / 100}
            {location.woodenfloor === true ? " + PODŁOGA" : true}
          </Popup>
        </Marker>
      ))}
      <div className={`map-menu ${isSearchBarFocused ? "active" : ""}`}>
        <input
          type="text"
          className="map-searchbar"
          placeholder="Wprowadź kod pocztowy"
          onFocus={handleSearchBarFocus}
          onBlur={handleSearchBarBlur}
          onKeyUp={handleKeyPress}
        />
        {newLat === null ||
        newLng === null ||
        newLat === 52.215933 ||
        newLng === 19.134422 ||
        newPostalCode.length !== 6 ? (
          <p className=".error-p">Niepoprawny kod pocztowy</p>
        ) : (
          true
        )}
        <button
          onClick={() => {
            handleShowPosition();
            // document.getElementsByClassName(".error-p")[0].style.display =
            //   "block";
          }}
        >
          Pokaż na mapie
        </button>
      </div>

      <RoutingControl
        waypoints={[L.latLng(51.5, -0.08), L.latLng(51.505, -0.06)]}
      />
    </MapContainer>
  );
}

export default Map;
