import React from "react";
import Axios from "axios";
import "../styles/home.scss";
import { MdOutlineAdd, MdGpsFixed } from "react-icons/md";
import { FaSort } from "react-icons/fa";
import { BsSearch } from "react-icons/bs";
import { FaSpinner } from "react-icons/fa";
import { BiLabel, BiSolidLabel } from "react-icons/bi";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAuthContext } from "../hooks/useAuthContext";

import KennelOrderItem from "../components/KennelOrderItem";

function Home({ refresh }) {
  const [searchString, setSearchString] = useState("");
  const [voivodeship, setVoivodeship] = useState("");
  const [status, setStatus] = useState("w trakcie realizacji");
  const [kennelOrdersList, setKennelOrdersList] = useState([]);
  const [sortOrderDate, setSortOrderDate] = useState("desc");
  const [loading, setLoading] = useState(false);
  const { user } = useAuthContext();

  const handleInputChange = (e) => {
    setSearchString(e.target.value);
  };
  const handleVoivodeshipChange = (e) => {
    setVoivodeship(e.target.value);
  };
  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const handleClearFilters = () => {
    setSearchString("");
    setVoivodeship("");
    setStatus("w trakcie realizacji");
    document.querySelector(".clear-filters").classList.add("not-visible");
    document.querySelector(".clear-filters").classList.remove("visible");
    document.querySelector(".search-field").value = "";
    document.querySelector(".status-select").value = "w trakcie realizacji";
    document.querySelector(".voivodeship-select").value = "";
  };

  useEffect(() => {
    setLoading(true); // Set loading to true before making the request
    if (user) {
      Axios.get("/api/kennels", {
        params: {
          searchString,
          voivodeship,
          status,
          sortOrderDate,
        },
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
        .then((response) => {
          setKennelOrdersList(response.data);
          console.log(response.data)
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          setLoading(false); // Set loading to false after the request is complete
        });
    }
  }, [searchString, voivodeship, status, refresh, sortOrderDate, user]);

  useEffect(() => {
    handleClearFilters();
  }, [refresh]);

  return (
    <div className="main">
      <div className="header">
        <h1>Panel zamówień | KOJCE</h1>
        <div className="header-options">
          <Link to={"/new"} className="create-order">
            <MdOutlineAdd />
            Dodaj nowe zamówienie
          </Link>
        </div>
      </div>
      <hr />
      <div className="filter">
        <div className="filter-col1">
          <div className="searchbar filter-option">
            <div className="search-icon">
              <BsSearch />
            </div>
            <input
              type="text"
              className="search-field"
              placeholder="Szukaj po frazie..."
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="filter-col2">
          <div className="status filter-option">
            <div className="status-icon">
              {status === "" ? <BiLabel /> : <BiSolidLabel />}
            </div>
            <select
              className="status-select"
              onChange={handleStatusChange}
              value={status}
            >
              <option value="">Status</option>
              <option value="w trakcie realizacji">W trakcie realizacji</option>
              <option value="zrealizowane">Zrealizowane</option>
            </select>
          </div>
          <div className="voivodeship filter-option">
            <div className="voivodeship-icon">
              <MdGpsFixed />
            </div>
            <select
              className="voivodeship-select"
              onChange={handleVoivodeshipChange}
            >
              <option value="">Województwo</option>
              <option value="dolnośląskie">dolnośląskie</option>
              <option value="kujawsko-pomorskie">kujawsko-pomorskie</option>
              <option value="lubelskie">lubelskie</option>
              <option value="lubuskie">lubuskie</option>
              <option value="łódzkie">łódzkie</option>
              <option value="małopolskie">małopolskie</option>
              <option value="mazowieckie">mazowieckie</option>
              <option value="opolskie">opolskie</option>
              <option value="podkarpackie">podkarpackie</option>
              <option value="podlaskie">podlaskie</option>
              <option value="pomorskie">pomorskie</option>
              <option value="śląskie">śląskie</option>
              <option value="świętokrzyskie">świętokrzyskie</option>
              <option value="warmińsko-mazurskie">warmińsko-mazurskie</option>
              <option value="wielkopolskie">wielkopolskie</option>
              <option value="zachodniopomorskie">zachodniopomorskie</option>
            </select>
          </div>
        </div>
      </div>
      <hr />
      <div className="sort">
        <div
          className="number"
          onClick={() => {
            setSortOrderDate(sortOrderDate === "asc" ? "desc" : "asc");
          }}
        >
          <p>numer</p>
          <FaSort />
        </div>
        <div
          className="date"
          onClick={() => {
            setSortOrderDate(sortOrderDate === "asc" ? "desc" : "asc");
          }}
        >
          <p>data</p>
          <FaSort />
        </div>
        <div className="customer">
          <p>klient</p>
        </div>
        <div className="size">
          <p>wymiar</p>
        </div>
        <div className="createdby">
          <p>dodane przez</p>
        </div>
        <div className="status">
          <p>status</p>
        </div>
        <div className="voivodeship">
          <p>województwo</p>
        </div>
        <div className="price">
          <p>cena</p>
        </div>

        <button
          className="clear-filters not-visible"
          onClick={handleClearFilters}
        >
          Wyczyść filtry
        </button>
      </div>
      <div className="content">
        {loading ? (
          <div className="spinner-container">
            <FaSpinner className="spinner" />
          </div>
        ) : (
          kennelOrdersList.map((kennelOrder) => (
            <KennelOrderItem
              key={kennelOrder.ordernumber}
              kennelOrder={kennelOrder}
            />
          ))
        )}
      </div>
    </div>
  );
}

export default Home;
