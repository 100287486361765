import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import Axios from "axios";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { AiFillEdit, AiFillDelete, AiFillCheckCircle } from "react-icons/ai";
import { BiSolidDownload } from "react-icons/bi";
import { FaSpinner } from "react-icons/fa";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import "../styles/order.scss";
import { useAuthContext } from "../hooks/useAuthContext";

function Order() {
  const { id } = useParams();

  const [kennelOrderData, setKennelOrderData] = useState([]);
  const [deleteAlertVisibility, setDeleteAlertVisibility] = useState(false);
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const { user } = useAuthContext();

  const handleDeleteAlertVisibility = () => {
    const element = document.getElementsByClassName("order-deletealert")[0];
    deleteAlertVisibility === false
      ? element.classList.add("show")
      : element.classList.remove("show");
    setDeleteAlertVisibility(!deleteAlertVisibility);
  };
  const calculateDeliveryTime = (dateString, estimatedTime) => {
    const originalDate = new Date(dateString);

    const newDate = new Date(originalDate);
    newDate.setDate(originalDate.getDate() + estimatedTime - 2);

    const earliest = newDate.toISOString();

    newDate.setDate(originalDate.getDate() + estimatedTime + 5);

    const latest = newDate.toISOString();

    const estimatedDeliveryTime =
      earliest.slice(8, 10) +
      "." +
      earliest.slice(5, 7) +
      " - " +
      latest.slice(8, 10) +
      "." +
      latest.slice(5, 7);
    return estimatedDeliveryTime;
  };

  const handleDownloadImage = () => {
    const element = document.getElementById("order-confirmation");

    const originalStyles = {
      width: element.style.width,
      height: element.style.height,
      display: element.style.display,
    };

    element.style.width = "1920px";
    element.style.height = "1080px";

    html2canvas(element).then((canvas) => {
      const data = canvas.toDataURL("image/jpeg");
      const link = document.createElement("a");

      link.href = data;
      link.download = `Zamowienie_${kennelOrderData.ordernumber}.jpg`;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });

    element.style.width = originalStyles.width;
    element.style.height = originalStyles.height;
  };

  const handleDownloadPDF = () => {
    const element = document.getElementById("order-confirmation");
    const originalStyles = {
      width: element.style.width,
      height: element.style.height,
      display: element.style.display,
    };

    element.style.width = "1920px";
    element.style.height = "1080px";
    html2canvas(element).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpeg");

      const pdf = new jsPDF({
        orientation: "landscape",
        unit: "mm",
        format: "a4",
      });

      const imgWidth = pdf.internal.pageSize.getWidth();
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const yOffset = (pdf.internal.pageSize.getHeight() - imgHeight) / 2;

      pdf.addImage(imgData, "JPEG", 0, yOffset, imgWidth, imgHeight);

      pdf.save(`Zamowienie_${kennelOrderData.ordernumber}.pdf`);
      element.style.width = originalStyles.width;
      element.style.height = originalStyles.height;
    });
  };

  const navigate = useNavigate();

  const handleDeleteKennelOrder = () => {
    deleteKennelOrder();
    navigate("/");
  };

  const deleteKennelOrder = () => {
    if (user) {
      Axios.patch(
        `/api/kennels/${id}`,
        { deleted: true },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      )
        .then(() => {})
        .catch((error) => {
          console.error("Error deleting this order:", error);
        });
    }
  };

  const handleOrderStatus = () => {
    if (kennelOrderData.status === "w trakcie realizacji") {
      updateKennelOrderStatus("zrealizowane");
    } else {
      updateKennelOrderStatus("w trakcie realizacji");
    }
  };

  const updateKennelOrderStatus = (newStatus) => {
    if (user) {
      Axios.patch(
        `/api/kennels/${id}`,
        {
          status: newStatus,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      )
        .then()
        .catch((error) => {
          console.error("Error updating order status:", error);
        });
      setStatus(newStatus);
    }
  };

  useEffect(() => {
    if (user) {
      setLoading(true);
      Axios.get(`/api/kennels/${id}`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
        .then(({ data }) => {
          setKennelOrderData(data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          setLoading(false); // Set loading to false after the request is complete
        });
    }
  }, [status, id, user]);

  function formatPhoneNumber(phoneNumber) {
    if (!phoneNumber) return "";

    const digitsOnly = phoneNumber.replace(/\D/g, "");

    if (digitsOnly.length > 9) {
      return `+${digitsOnly.slice(0, 3)} ${digitsOnly.slice(
        3,
        6
      )} ${digitsOnly.slice(6, 9)} ${digitsOnly.slice(9)}`;
    } else {
      return `+48 ${digitsOnly.slice(0, 3)} ${digitsOnly.slice(
        3,
        6
      )} ${digitsOnly.slice(6)}`;
    }
  }

  function formatNIP(nip) {
    if (!nip) return ""; // Handle empty NIP

    // Remove any non-digit characters
    const digitsOnly = nip.replace(/\D/g, "");

    // Add hyphens at the specified positions
    const formattedNIP = digitsOnly.replace(
      /(\d{3})(\d{3})(\d{2})(\d{2})/,
      "$1-$2-$3-$4"
    );

    return formattedNIP;
  }
  return (
    <div className="order-main">
      <div className="order-header">
        <h1>Zamówienie {id}</h1>
      </div>
      <hr />
      {loading ? (
        <div className="spinner-container">
          <FaSpinner className="spinner" />
        </div>
      ) : (
        <div className="order-data" id="order-confirmation">
          <div className="order-fullinfo">
            <div className="order-customerinfo">
              <p>
                {kennelOrderData.name} {kennelOrderData.surname}
              </p>
              <p>
                {kennelOrderData.postalcode} {kennelOrderData.city}{" "}
                {kennelOrderData.street === ""
                  ? kennelOrderData.flatnumber === ""
                    ? kennelOrderData.housenumber
                    : kennelOrderData.housenumber +
                      "/" +
                      kennelOrderData.flatnumber
                  : ""}
              </p>
              {kennelOrderData.street === "" ? (
                true
              ) : (
                <p>
                  ul.{" "}
                  {kennelOrderData.street +
                    " " +
                    (kennelOrderData.flatnumber === ""
                      ? kennelOrderData.housenumber
                      : kennelOrderData.housenumber +
                        "/" +
                        kennelOrderData.flatnumber)}
                </p>
              )}
              <p>{formatPhoneNumber(kennelOrderData.phonenumber1)}</p>
              {kennelOrderData.phonenumber2 === "" ? (
                true
              ) : (
                <p>{formatPhoneNumber(kennelOrderData.phonenumber2)}</p>
              )}
              {kennelOrderData.email === "" ? (
                true
              ) : (
                <p>{kennelOrderData.email}</p>
              )}
              {kennelOrderData.nip === "" ? (
                true
              ) : (
                <p>NIP : {formatNIP(kennelOrderData.nip)}</p>
              )}
              <br />
              <p>
                <b>{kennelOrderData.voivodeship}</b>
              </p>
              <p>
                <b>{kennelOrderData.price} zł</b>
              </p>
              {kennelOrderData && kennelOrderData.date && (
                <p className="order-firstinfo">
                  Przewidywany termin dostawy:{" "}
                  <b>
                    {calculateDeliveryTime(
                      kennelOrderData.date,
                      kennelOrderData.deliverytime
                    )}
                  </b>
                </p>
              )}
              <p>
                <i>
                  Nasza firma będzie kontaktować się z Państwem dzień przed
                  przyjazdem oraz poinformuje o dokładnej godzinie montażu.
                </i>
              </p>
              <p>
                W celu dokonania zmian w zamówieniu lub otrzymania dodatkowych
                informacji prosimy o kontakt pod numerem: <b>517 945 287</b>
              </p>
              <p>
                Numer zamówienia: <b>{"" + kennelOrderData.ordernumber}</b>
              </p>
              <p>
                Data złożenia zamówienia:{" "}
                <b>
                  {kennelOrderData &&
                    kennelOrderData.date &&
                    kennelOrderData.date.slice(0, 10)}
                </b>
              </p>
            </div>
            <div className="order-productinfo">
              <p>
                <b>
                  Kojec dla psa {kennelOrderData.widthcm / 100}×
                  {kennelOrderData.lengthcm / 100}
                </b>
              </p>
              <hr />
              <p>
                Szerokość (przód i tył):{" "}
                {kennelOrderData.exactsize === false
                  ? kennelOrderData.widthcm - 4
                  : kennelOrderData.widthcm}{" "}
                cm
              </p>
              <p>
                Długość (boki):{" "}
                {kennelOrderData.exactsize === false
                  ? kennelOrderData.lengthcm - 4
                  : kennelOrderData.lengthcm}{" "}
                cm
              </p>
              <p>Wyższa wysokość: {kennelOrderData.higherheightcm} cm</p>
              <p>Niższa wysokość: {kennelOrderData.lowerheightcm} cm</p>
              <p>Spad dachu: {kennelOrderData.roofpitch}</p>
              <p>Rodzaj blachy: {kennelOrderData.rooftype}</p>
              <p>
                Położenie furtki wejściowej: na {kennelOrderData.widthcm / 100}
                -metrowej ścianie - {kennelOrderData.gateposition}
              </p>
              <p>
                Podłoga: {kennelOrderData.woodenfloor === true ? "TAK" : "nie"}
              </p>
              <p>
                Obrotowe uchwyty na miski:{" "}
                {kennelOrderData.bowlsholders === true ? "TAK" : "nie"}
              </p>
              <p>
                Zabudowa ściany tylnej w drewnie:{" "}
                {kennelOrderData.rearwallcover === ""
                  ? "nie"
                  : kennelOrderData.rearwallcover}
              </p>
              <p>
                Zabudowa ściany lewej w drewnie:{" "}
                {kennelOrderData.leftwallcover === ""
                  ? "nie"
                  : kennelOrderData.leftwallcover}
              </p>
              <p>
                Zabudowa ściany prawej w drewnie:{" "}
                {kennelOrderData.rightwallcover === ""
                  ? "nie"
                  : kennelOrderData.rightwallcover}
              </p>
              <p>
                Zabudowa wiatrołapu:{" "}
                {kennelOrderData.roofcover === true ? "TAK" : "nie"}
              </p>
              <p>Malowanie farbą podkładową: TAK</p>
              <p>
                Malowanie farbą nawierzchniową:{" "}
                {kennelOrderData.surfacepaint === true ? "TAK" : "nie"}
              </p>
              <p>
                Ściana dzieląca kojec:{" "}
                {kennelOrderData.dividingwall === ""
                  ? "nie"
                  : kennelOrderData.dividingwall}
              </p>
              <p>
                Otwór na budę:{" "}
                {kennelOrderData.doghousehole === ""
                  ? "nie"
                  : kennelOrderData.doghousehole}
              </p>
              <p>
                Buda dla psa:{" "}
                {kennelOrderData.doghouse === ""
                  ? "nie"
                  : kennelOrderData.doghouse}
              </p>
              <p>
                Dodatkowe informacje: <br />
                <i>
                  <b>
                    {kennelOrderData.additionalinfo === ""
                      ? ""
                      : kennelOrderData.additionalinfo}
                  </b>
                </i>
              </p>
            </div>
          </div>
          <div className="order-shortinfo">
            <h3>Skrócony opis zamówienia</h3>
            <hr />
            <p>
              {kennelOrderData.widthcm / 100}×{kennelOrderData.lengthcm / 100}
              {kennelOrderData.exactsize === false ? true : " (ścisły wymiar)"}
              {kennelOrderData.woodenfloor === true ? " + PODŁOGA" : ""}
            </p>
            {"- drzwi " + kennelOrderData.gateposition}
            {kennelOrderData.bowlsholders === true ? <p>- miski</p> : true}
            {kennelOrderData.roofpitch !== "na tył" ||
            kennelOrderData.higherheightcm !== 175 ||
            kennelOrderData.lowerheightcm !== 155 ? (
              <p>- spad {kennelOrderData.roofpitch}</p>
            ) : (
              true
            )}
            {kennelOrderData.higherheightcm !== 175 ||
            kennelOrderData.lowerheightcm !== 155 ? (
              <p>
                - wysokości:{" "}
                {kennelOrderData.lowerheightcm +
                  "cm i " +
                  kennelOrderData.higherheightcm +
                  " cm"}
              </p>
            ) : (
              true
            )}
            {kennelOrderData.rearwallcover === "" ? (
              true
            ) : (
              <p>
                {"- zabudowa ściany tylnej " + kennelOrderData.rearwallcover}
              </p>
            )}
            {kennelOrderData.leftwallcover === "" ? (
              true
            ) : (
              <p>
                {"- zabudowa ściany lewej " + kennelOrderData.leftwallcover}
              </p>
            )}
            {kennelOrderData.rightwallcover === "" ? (
              true
            ) : (
              <p>
                {"- zabudowa ściany prawej " + kennelOrderData.rightwallcover}
              </p>
            )}
            {kennelOrderData.roofcover !== true ? (
              true
            ) : (
              <p>{"- zabudowa wiatrołapu"}</p>
            )}
            {kennelOrderData.surfacepaint !== true ? (
              true
            ) : (
              <p>{"- farba nawierzchniowa"}</p>
            )}
            {kennelOrderData.dividingwall === "" ? (
              true
            ) : (
              <p>{"- ściana działowa: " + kennelOrderData.dividingwall}</p>
            )}
            {kennelOrderData.doghousehole === "" ? (
              true
            ) : (
              <p>{"- otwór na budę " + kennelOrderData.doghousehole}</p>
            )}
            {kennelOrderData.doghouse === "" ? (
              true
            ) : (
              <p>{"- buda " + kennelOrderData.doghouse}</p>
            )}
            {kennelOrderData.additionalinfo === "" ? (
              ""
            ) : (
              <p>
                Dodatkowe informacje: <br />
                <i>
                  <b>{kennelOrderData.additionalinfo}</b>
                </i>
              </p>
            )}
          </div>
        </div>
      )}
      <div className="order-manage">
        <Link to={`/edit/${id}`}>
          <AiFillEdit />
          Edytuj
        </Link>
        <button onClick={handleDownloadImage}>
          <BiSolidDownload />
          Pobierz (JPG)
        </button>
        <button onClick={handleDownloadPDF}>
          <BiSolidDownload />
          Pobierz (PDF)
        </button>
        <button
          style={{ backgroundColor: "salmon" }}
          onClick={handleDeleteAlertVisibility}
        >
          <AiFillDelete />
          Usuń
        </button>
        {kennelOrderData.status === "zrealizowane" ? (
          true
        ) : (
          <button
            style={{ backgroundColor: "lightgreen" }}
            onClick={handleOrderStatus}
          >
            <AiFillCheckCircle />
            Oznacz jako "zrealizowane"
          </button>
        )}
        {kennelOrderData.status === "w trakcie realizacji" ? (
          true
        ) : (
          <button
            style={{ backgroundColor: "gray" }}
            onClick={handleOrderStatus}
          >
            <AiFillCheckCircle />
            Oznacz jako "w trakcie realizacji"
          </button>
        )}
        <div className="order-navigatepreviousnext">
          <Link to={`/order/${parseInt(id, 10) + 1}`} className="order-next">
            Następne Zamówienie
            <BsFillArrowRightCircleFill />
          </Link>
        </div>
      </div>
      <div className="order-deletealert">
        <p>Czy na pewno chcesz usunąć to zamówienie?</p>
        <div className="order-deletealertoptions">
          <button onClick={handleDeleteKennelOrder}>
            TAK, chcę usunąć to zamówienie
          </button>
          <button onClick={handleDeleteAlertVisibility}>NIE</button>
        </div>
      </div>
    </div>
  );
}

export default Order;
