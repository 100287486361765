import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Axios from "axios";
import { useAuthContext } from "../hooks/useAuthContext";

const Edit = () => {
  const { id } = useParams();
  const geocageApiKey = "6df0b0a3516e4018b5dafd3b161b6a05";
  const [voivodeship, setVoivodeship] = useState("");
  const [cities, setCities] = useState([]);
  const { user } = useAuthContext();

  async function geocodeAddress(orderData) {
    try {
      const placename = encodeURIComponent(
        orderData.street === ""
          ? `${orderData.postalcode} ${orderData.city} ${orderData.housenumber}, POLAND`
          : `${orderData.postalcode} ${orderData.city}, ${orderData.street} ${orderData.housenumber}, POLAND`
      );
      const response = await Axios.get(
        `https://api.opencagedata.com/geocode/v1/json?q=${placename}&key=${geocageApiKey}&language=pl`
      );
      orderData.lat = response.data.results[0].geometry.lat;
      orderData.lng = response.data.results[0].geometry.lng;
    } catch (error) {
      console.error("Error:", error.message);
    }
  }

  async function getVoivodeship(orderData) {
    try {
      const response = await Axios.get(
        `https://polish-zip-codes1.p.rapidapi.com/${orderData.postalcode}`,
        {
          headers: {
            Accept: "application/json",
            "X-RapidAPI-Key":
              "1de7e35093msh8ab8487a64ab16ap122ac4jsn840a1ee8a7c4",
            "X-RapidAPI-Host": "polish-zip-codes1.p.rapidapi.com",
          },
        }
      );
      orderData.voivodeship = response.data[0].wojewodztwo;
      setVoivodeship(response.data[0].wojewodztwo);
      const extractedCities = response.data.map((data) => data.miejscowosc);
      const uniqueCities = new Set();
      const uniqueArray = [];
      extractedCities.forEach((city) => {
        if (!uniqueCities.has(city)) {
          uniqueCities.add(city);
          uniqueArray.push(city);
        }
      });
      setCities(uniqueArray);
      const citySelect = document.getElementsByName("city")[0];
      citySelect.disabled = false;
    } catch (error) {
      console.error("Error:", error.message);
      orderData.voivodeship = "";
      setVoivodeship("");
      const citySelect = document.getElementsByName("city")[0];
      citySelect.value = "";
      orderData.city = "";
      citySelect.disabled = true;
    }
  }

  const [orderData, setOrderData] = useState([]);
  const handleGetVoivodeship = () => {
    getVoivodeship(orderData);
  };

  useEffect(() => {
    orderData.city = cities[0];
  }, [voivodeship]);

  useEffect(() => {
    if (user) {
      Axios.get(`/api/kennels/${id}`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
        .then((response) => {
          setOrderData(response.data);
          console.log(response.data)
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [user]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setOrderData({
      ...orderData,
      [name]: value,
    });
  };

  const handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setOrderData((prevData) => ({
      ...prevData,
      exactsize: isChecked,
    }));
  };

  const navigate = useNavigate();

  const changeKennelOrder = async () => {
    if (user) {
      await geocodeAddress(orderData);
      Axios.patch(`/api/kennels/${id}`, orderData, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
        .then((response) => {
          navigate(`/order/${id}`);
        })
        .catch((error) => {
          console.error("Error updating order:", error);
        });
    }
  };
  return (
    <div className="new-main">
      <div className="new-personal-info">
        <div>
          <p>Imię</p>
          <input
            type="text"
            name="name"
            value={orderData.name}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <p>Nazwisko</p>
          <input
            type="text"
            name="surname"
            value={orderData.surname}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <p>Województwo</p>
          <p className="voivodeship">{orderData.voivodeship}</p>
        </div>
        <div>
          <p>Kod pocztowy</p>
          <input
            type="text"
            name="postalcode"
            value={orderData.postalcode}
            onChange={handleInputChange}
            onBlur={handleGetVoivodeship}
          />
        </div>
        <div>
          <p>Miejscowość</p>
          <select
            name="city"
            value={orderData.city}
            onChange={handleInputChange}
            disabled={true}
          >
            {cities.map((city) => (
              <option key={city} value={city}>
                {city}
              </option>
            ))}
          </select>
        </div>
        <div>
          <p>Ulica</p>
          <input
            type="text"
            name="street"
            value={orderData.street}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <p>Numer domu</p>
          <input
            type="text"
            name="housenumber"
            value={orderData.housenumber}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <p>Numer lokalu</p>
          <input
            type="text"
            name="flatnumber"
            value={orderData.flatnumber}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <p>Numer telefonu 1</p>
          <input
            type="text"
            name="phonenumber1"
            value={orderData.phonenumber1}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <p>Numer telefonu 2</p>
          <input
            type="text"
            name="phonenumber2"
            value={orderData.phonenumber2}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <p>Email</p>
          <input
            type="text"
            name="email"
            value={orderData.email}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <p>NIP</p>
          <input
            type="text"
            name="nip"
            value={orderData.nip}
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className="new-product-info">
        <div>
          <p>Cena</p>
          <input
            type="text"
            name="price"
            value={orderData.price}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <p>Ścisły wymiar</p>
          <input
            type="checkbox"
            name="exactsize"
            checked={orderData.exactsize}
            onChange={handleCheckboxChange}
          />
        </div>
        <div>
          <p>Szerokość (cm)</p>
          <input
            type="text"
            name="widthcm"
            value={orderData.widthcm}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <p>Długość (cm)</p>
          <input
            type="text"
            name="lengthcm"
            value={orderData.lengthcm}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <p>Wyższa wysokość (cm)</p>
          <input
            type="text"
            name="higherheightcm"
            value={orderData.higherheightcm}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <p>Niższa wysokość (cm)</p>
          <input
            type="text"
            name="lowerheightcm"
            value={orderData.lowerheightcm}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <p>Spad dachu</p>
          <select
            name="roofpitch"
            value={orderData.roofpitch}
            onChange={handleInputChange}
          >
            <option value="na tył">na tył</option>
            <option value="na przód">na przód</option>
            <option value="na prawo">na prawo</option>
            <option value="na lewo">na lewo</option>
          </select>
        </div>
        <div>
          <p>Rodzaj blachy</p>
          <select
            name="rooftype"
            value={orderData.rooftype}
            onChange={handleInputChange}
          >
            <option value="ocynk">ocynk</option>
            <option value="akryl">akryl</option>
          </select>
        </div>
        <div>
          <p>Podłoga</p>
          <select
            name="woodenfloor"
            value={orderData.woodenfloor}
            onChange={handleInputChange}
          >
            <option value="0">NIE</option>
            <option value="1">TAK</option>
          </select>
        </div>
        <div>
          <p>Położenie furtki</p>
          <select
            name="gateposition"
            value={orderData.gateposition}
            onChange={handleInputChange}
          >
            <option value="po prawej, zawiasy po prawej">
              po prawej, zawiasy po prawej
            </option>
            <option value="po prawej, zawiasy po lewej">
              po prawej, zawiasy po lewej
            </option>
            <option value="po lewej, zawiasy po lewej">
              po lewej, zawiasy po lewej
            </option>
            <option value="po lewej, zawiasy po prawej">
              po lewej, zawiasy po prawej
            </option>
          </select>
        </div>
        <div>
          <p>Miski</p>
          <select
            name="bowlsholders"
            value={orderData.bowlsholders}
            onChange={handleInputChange}
          >
            <option value="0">NIE</option>
            <option value="1">TAK</option>
          </select>
        </div>
        <div>
          <p>Zabudowa ściany tylnej</p>
          <select
            name="rearwallcover"
            value={orderData.rearwallcover}
            onChange={handleInputChange}
          >
            <option value="">NIE</option>
            <option value="w całości">w całości</option>
            <option value="w połowie poziomo">w połowie poziomo</option>
            <option value="w połowie pionowo">w połowie pionowo</option>
          </select>
        </div>
        <div>
          <p>Zabudowa ściany lewej</p>
          <select
            name="leftwallcover"
            value={orderData.leftwallcover}
            onChange={handleInputChange}
          >
            <option value="">NIE</option>
            <option value="w całości">w całości</option>
            <option value="w połowie poziomo">w połowie poziomo</option>
            <option value="w połowie pionowo">w połowie pionowo</option>
          </select>
        </div>
        <div>
          <p>Zabudowa ściany prawej</p>
          <select
            name="rightwallcover"
            value={orderData.rightwallcover}
            onChange={handleInputChange}
          >
            <option value="">NIE</option>
            <option value="w całości">w całości</option>
            <option value="w połowie poziomo">w połowie poziomo</option>
            <option value="w połowie pionowo">w połowie pionowo</option>
          </select>
        </div>
        <div>
          <p>Zabudowa wiatrołapu</p>
          <select
            name="roofcover"
            value={orderData.roofcover}
            onChange={handleInputChange}
          >
            <option value="0">NIE</option>
            <option value="1">TAK</option>
          </select>
        </div>
        <div>
          <p>Farba nawierzchniowa</p>
          <select
            name="surfacepaint"
            value={orderData.surfacepaint}
            onChange={handleInputChange}
          >
            <option value="0">NIE</option>
            <option value="1">TAK</option>
          </select>
        </div>
        <div>
          <p>Ściana działowa</p>
          <input
            type="text"
            name="dividingwall"
            value={orderData.dividingwall}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <p>Otwór na budę</p>
          <input
            type="text"
            name="doghousehole"
            value={orderData.doghousehole}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <p>Buda</p>
          <select
            name="doghouse"
            value={orderData.doghouse}
            onChange={handleInputChange}
          >
            <option value="0">NIE</option>
            <option value="S">S</option>
            <option value="M">M</option>
            <option value="L">L</option>
            <option value="XL">XL</option>
            <option value="XXL">XXL</option>
          </select>
        </div>
        <div>
          <p>Dodatkowe informacje</p>
          <textarea
            name="additionalinfo"
            value={orderData.additionalinfo}
            onChange={handleInputChange}
            cols="30"
            rows="10"
          ></textarea>
        </div>
      </div>
      <button
        className="new-order-submit"
        type="submit"
        onClick={changeKennelOrder}
      >
        Zmień zamówienie
      </button>
    </div>
  );
};

export default Edit;
